import React from 'react';
import type { WCmsEventView } from '@zola/svc-web-api-ts-client';
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { sharedButtonStyles } from '../Schedule.styles';

const AddToCalendarForm = ({ event }: { event: WCmsEventView }): JSX.Element => {
  const {
    state: {
      components: { ThemedButton },
    },
  } = useWebsiteThemeContext();

  const buildEventLocationString = (): string => {
    return ['venue_name', 'address_1', 'address_2', 'city', 'state_province', 'postal_code']
      .reduce((values, property) => {
        const value = event[property as keyof WCmsEventView];
        if (value) values.push(value as string);
        return values;
      }, [] as string[])
      .join(' ');
  };

  return (
    <form action="/web-api/v1/public-calendar/event" method="post">
      <input
        type="hidden"
        name="start"
        value={formatDateUtc(event.start_at as string, 'YYYY-MM-DD HH:mm')}
      />
      <input
        type="hidden"
        name="end"
        value={event.end_at ? formatDateUtc(event.end_at, 'YYYY-MM-DD HH:mm') : ''}
      />
      <input type="hidden" name="title" value={event.name} />
      <input type="hidden" name="description" value={event.note || ''} />
      <input type="hidden" name="location" value={buildEventLocationString()} />
      <ThemedButton type="submit" css={sharedButtonStyles}>
        Add to calendar
      </ThemedButton>
    </form>
  );
};

export default AddToCalendarForm;
